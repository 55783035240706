@media screen and (min-width: 1920px) {
    .jf-resume-header {
        width: 50vw;
        margin-right: -1%;
        margin-top: 2%;
    }

    #h1{
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 2rem;
        line-height: 140%;
        width: 50vw;
        margin-left: 25vw;
        text-align: left;
    }
    
    .profile {
        margin-top: -5%;
    }
    
    .jf-resume-arrow {
        margin-top: 1%;
        width: 150px;
    }
    
    .jf-resume-2021 {
        width: 60vw;
        margin-bottom: 5%;
        margin-top: 2%;
    }

    .web-layout-1, .web-layout-2, .web-layout-3, .web-layout-4 {
        display: none;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1920px) {
    .jf-resume-header {
        width: 50vw;
        margin-right: -1%;
        margin-top: 2%;
    }
    
    .profile {
        margin-top: -5%;
    }
    
    h1 {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 140%;
        width: 70vw;
        text-align: left;
        margin-left: 16%;
    }
    
    .jf-resume-arrow {
        margin-top: -1%;
        width: 150px;
    }
    
    .jf-resume-2021 {
        width: 78vw;
        margin-bottom: 5%;
    }

    .web-layout-1, .web-layout-2, .web-layout-3, .web-layout-4 {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .sub-division-1 {
        max-height: 15vh;
        margin-right: -1%;
        margin-top: 5vh;
    }

    .jf-resume-header {
        max-height: 15vh;
    }
    
    .profile {
        margin-top: -5%;
    }
    
    #h1 {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 140%;
        width: 85vw;
        text-align: left;
        margin-left: 8%;
    }
    
    .sub-division-2 {
        margin-top: -1%;
        max-height: 8vh;
    }

    .jf-resume-arrow {
        max-height: 13vh;
    }
    
    .sub-division-3 {
        max-height: 120vh;
        margin-bottom: 5%;
        margin-top: 4vh;
    }

    .jf-resume-2021 {
        max-height: 120vh;
    }

    .web-layout-1, .web-layout-2, .web-layout-3, .web-layout-4 {
        display: none;
    }
}

@media screen and (min-width: 640px) and (max-width: 768px) {
    .jf-resume-header {
        width: 80vw;
        margin-right: -1%;
        margin-top: 2%;
    }

    .profile {
        margin-top: 4%;
    }
    
    h1 {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1.2em;
        line-height: 140%;
        width: 75vw;
        text-align: left;
        margin-left: 13%;
        letter-spacing: .1em;
    }
    
    .jf-resume-arrow {
        width: 150px;
        margin-bottom: 3%;
    }
    
    .jf-resume-2021 {
        display: none;
    }

    .web-layout-1, .web-layout-2, .web-layout-3, .web-layout-4 {
        width: 70vw;
    }

    .web-layout-1 {
        margin-top: -5%;
    }

    .web-layout-2, .web-layout-3, .web-layout-4 {
        margin-top: 5%;
    }

    .web-layout-4 {
        margin-bottom: 8%;
    }
}

@media screen and (max-width: 640px) {
    .jf-resume-header {
        width: 90vw;
        margin-right: -1%;
        margin-top: 10%;
    }

    .profile {
        margin-top: 4%;
    }
    
    h1 {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1em;
        line-height: 140%;
        width: 80vw;
        text-align: left;
        margin-left: 10%;
        letter-spacing: .1em;
    }
    
    .jf-resume-arrow {
        width: 25vw;
        margin-bottom: 3%;
        margin-top: -2%;
    }
    
    .jf-resume-2021 {
        display: none;
    }

    .web-layout-1, .web-layout-2, .web-layout-3, .web-layout-4 {
        width: 85vw;
        margin-left: 5vw;
    }

    .web-layout-1 {
        margin-top: -5%;
    }

    .web-layout-2, .web-layout-3, .web-layout-4 {
        margin-top: 5%;
    }

    .web-layout-4 {
        margin-bottom: 8%;
    }
}