@media screen and (min-width: 1920px) {
    .code {
        background-color: black;
        color: black;
        height: 2130vh;
        margin-top: 2.2vh;
        display: flex;
        flex-direction: column;
        
    }

    .frisky-figures-image {
        max-width: 50vw;
    }

    .frisky-figures {
        margin-bottom: 0vh;
    }

    .frisky-website-image {
        max-width: 50vw;
    }

    .g-mafia-bat-image {
        max-width: 50vw;
    }

    .efficient-frontier-image {
        max-width: 30vw;
    }

    .bug-catcher-login {
        max-width: 50vw;
    }

    .bug-catcher-dash {
        max-width: 50vw;
    }

    .bug-catcher-view {
        max-width: 50vw;
    }

    .pho-banner-image {
        max-width: 50vw;
    }

    .pho-home-image {
        max-width: 50vw;
    }

    .pho-diag-image {
        max-width: 50vw;
    }

    .rec-home-image {
        max-width: 50vw;
    }

    .okcupid-image-1 {
        max-width: 50vw;
    }

    .okcupid-image-2 {
        max-width: 35vw;
    }

    .pierre-image {
        max-width: 50vw;
    }

    .pneum-image-1 {
        max-width: 50vw;
    }

    .pneum-image-2 {
        max-width: 50vw;
    }

    .asl-image-1 {
        max-width: 50vw;
    }

    .asl-image-2 {
        max-width: 50vw;
    }

    .rat-website-image {
        max-width: 50vw;
    }

    .outsite-image {
        max-width: 50vw;
    }

    .decent-labs-image {
        max-width: 50vw;
    }

    .miniExtensions-image {
        max-width: 50vw;
    }

    video {
        max-width: 50vw;
    }

    .creative-image {
        display: none;
    }

    .creative-image-2 {
        display: none;
    }

    .creative-image-3 {
        display: none;
    }

    .title {
        width: 100%;
    }

    .titleText {
        font-size: 3rem;
        color: rgba(255, 255, 255, 1);
        position: absolute;
        margin-top: 44vh;
        margin-left: 46.65vw;
        background-color: rgba(80, 200, 120, .5);
        font-weight: 700;
        z-index: 5;
    }

    .intro {
        color:rgba(255, 255, 255, 1);
        font-size: 3rem;
        text-align: center;
        margin-bottom: 10vh;
        font-weight: 300;
        margin-top: 10vh;
        text-decoration: underline;
    }

    .slide {
        padding-top: 2vh;
        background-color: black;
        margin-top: -2vh;
        width: 100%;
        text-align: center;
        margin-bottom: 16vh;
    }

    .slide-title {
        color:rgba(255, 255, 255, 1);
        font-size: 2rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        margin-top: 7vh;
        max-width: 50vw;
        margin-left: 24vw;
    }

    .description {
        color:rgba(255, 255, 255, 1);
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        text-align: left;
        margin-left: 24.5vw;
        width: 52%;
        line-height: 2rem;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1920px) {
    .code {
        background-color: black;
        color: black;
        height: 2080vh;
        margin-top: 4vh;
        display: flex;
        flex-direction: column;
        
    }

    .frisky-figures-image {
        max-width: 50vw;
    }

    .frisky-figures {
        margin-bottom: 0vh;
    }

    .frisky-website-image {
        max-width: 50vw;
    }

    .g-mafia-bat-image {
        max-width: 50vw;
    }

    .efficient-frontier-image {
        max-width: 30vw;
    }

    .bug-catcher-login {
        max-width: 50vw;
    }

    .bug-catcher-dash {
        max-width: 50vw;
    }

    .bug-catcher-view {
        max-width: 50vw;
    }

    .pho-banner-image {
        max-width: 50vw;
    }

    .pho-home-image {
        max-width: 50vw;
    }

    .pho-diag-image {
        max-width: 50vw;
    }

    .rec-home-image {
        max-width: 50vw;
    }

    .okcupid-image-1 {
        max-width: 50vw;
    }

    .okcupid-image-2 {
        max-width: 35vw;
    }

    .pierre-image {
        max-width: 50vw;
    }

    .pneum-image-1 {
        max-width: 50vw;
    }

    .pneum-image-2 {
        max-width: 50vw;
    }

    .asl-image-1 {
        max-width: 50vw;
    }

    .asl-image-2 {
        max-width: 50vw;
    }

    .rat-website-image {
        max-width: 50vw;
    }

    .outsite-image {
        max-width: 50vw;
    }

    .decent-labs-image {
        max-width: 50vw;
    }

    .miniExtensions-image {
        max-width: 50vw;
    }

    video {
        max-width: 50vw;
    }

    .creative-image {
        display: none;
    }

    .creative-image-2 {
        display: none;
    }

    .creative-image-3 {
        display: none;
    }

    .title {
        width: 100%;
    }

    .titleText {
        font-size: 3rem;
        color: rgba(255, 255, 255, 1);
        position: absolute;
        margin-top: 40.5vh;
        margin-left: 44.1vw;
        background-color: rgba(80, 200, 120, .5);
        font-weight: 700;
        z-index: 5;
    }

    .intro {
        color:rgba(255, 255, 255, 1);
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 10vh;
        font-weight: 300;
        margin-top: 10vh;
        text-decoration: underline;
    }

    .slide {
        padding-top: 2vh;
        background-color: black;
        margin-top: -2vh;
        width: 100%;
        text-align: center;
        margin-bottom: 16vh;
    }

    .slide-title {
        color:rgba(255, 255, 255, 1);
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        margin-top: 7vh;
        max-width: 50vw;
        margin-left: 24vw;
    }

    .description {
        color:rgba(255, 255, 255, 1);
        font-size: 1rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        text-align: left;
        margin-left: 24.5vw;
        width: 52%;
        line-height: 1.5rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .code {
        background-color: black;
        color: black;
        height: 1650vh;
        margin-top:3vh;
        display: flex;
        flex-direction: column;
    }

    .frisky-figures-image {
        max-width: 70vw;
    }

    .frisky-figures {
        margin-bottom: 0vh;
    }

    .frisky-website-image {
        max-width: 80vw;
    }

    .g-mafia-bat-image {
        max-width: 80vw;
    }

    .efficient-frontier-image {
        max-width: 80vw;
    }

    .bug-catcher-login {
        max-width: 80vw;
    }

    .bug-catcher-dash {
        max-width: 80vw;
    }

    .bug-catcher-view {
        max-width: 80vw;
    }

    .pho-banner-image {
        max-width: 80vw;
    }

    .pho-home-image {
        max-width: 80vw;
    }

    .pho-diag-image {
        max-width: 80vw;
    }

    .rec-home-image {
        max-width: 80vw;
    }

    .okcupid-image-1 {
        max-width: 80vw;
    }

    .okcupid-image-2 {
        max-width: 80vw;
    }

    .pierre-image {
        max-width: 80vw;
    }

    .pneum-image-1 {
        max-width: 80vw;
    }

    .pneum-image-2 {
        max-width: 80vw;
    }

    .asl-image-1 {
        max-width: 80vw;
    }

    .asl-image-2 {
        max-width: 80vw;
    }

    .rat-website-image {
        max-width: 80vw;
    }

    .outsite-image {
        max-width: 80vw;
    }

    .decent-labs-image {
        max-width: 80vw;
    }

    .miniExtensions-image {
        max-width: 80vw;
    }

    video {
        max-width: 50vw;
    }

    .creative-image {
        display: none;
    }

    .creative-image-2 {
        display: none;
    }

    .creative-image-3 {
        display: none;
    }

    .title {
        width: 100%;
    }

    .titleText {
        font-size: 3rem;
        color: rgba(255, 255, 255, 1);
        position: absolute;
        margin-top: 40vh;
        margin-left: 40vw;
        background-color: rgba(80, 200, 120, .5);
        font-weight: 700;
        z-index: 5;
    }

    .intro {
        color:rgba(255, 255, 255, 1);
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 10vh;
        font-weight: 300;
        margin-top: 10vh;
        text-decoration: underline;
    }

    .slide {
        padding-top: 2vh;
        background-color: black;
        margin-top: -2vh;
        width: 100%;
        text-align: center;
        margin-bottom: 7vh;
    }

    .slide-title {
        color:rgba(255, 255, 255, 1);
        font-size: 2rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        margin-top: 7vh;
        max-width: 70%;
        margin-left: 15vw;
    }

    .description {
        color:rgba(255, 255, 255, 1);
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        text-align: left;
        margin-left: 10vw;
        width: 80%;
        line-height: 1.8rem;
    }
}

@media screen and (min-width: 640px) and (max-width: 768px) {
    .code {
        background-color: black;
        color: black;
        height: 1880vh;
        margin-top: 4vh;
        display: flex;
        flex-direction: column;
        
    }

    .frisky-figures-image {
        max-width: 50vw;
    }

    .frisky-figures {
        margin-bottom: 0vh;
    }

    .frisky-website-image {
        max-width: 50vw;
    }

    .g-mafia-bat-image {
        max-width: 50vw;
    }

    .efficient-frontier-image {
        max-width: 30vw;
    }

    .bug-catcher-login {
        max-width: 50vw;
    }

    .bug-catcher-dash {
        max-width: 50vw;
    }

    .bug-catcher-view {
        max-width: 50vw;
    }

    .pho-banner-image {
        max-width: 50vw;
    }

    .pho-home-image {
        max-width: 50vw;
    }

    .pho-diag-image {
        max-width: 50vw;
    }

    .rec-home-image {
        max-width: 50vw;
    }

    .okcupid-image-1 {
        max-width: 50vw;
    }

    .okcupid-image-2 {
        max-width: 35vw;
    }

    .pierre-image {
        max-width: 50vw;
    }

    .pneum-image-1 {
        max-width: 50vw;
    }

    .pneum-image-2 {
        max-width: 50vw;
    }

    .asl-image-1 {
        max-width: 50vw;
    }

    .asl-image-2 {
        max-width: 50vw;
    }

    .rat-website-image {
        max-width: 50vw;
    }

    .outsite-image {
        max-width: 50vw;
    }

    .decent-labs-image {
        max-width: 50vw;
    }

    .miniExtensions-image {
        max-width: 50vw;
    }

    video {
        display: none;
    }

    .creative-image {
        width: 100vw;
    }

    .creative-image-2 {
        width: 100vw;
    }

    .creative-image-3 {
        width: 100vw;
    }

    .title {
        width: 100%;
    }

    .titleText {
        font-size: 3rem;
        color: rgba(255, 255, 255, 1);
        position: absolute;
        margin-top: 41.5vh;
        margin-left: 44.1vw;
        background-color: rgba(80, 200, 120, .5);
        font-weight: 700;
        z-index: 5;
    }

    .intro {
        color:rgba(255, 255, 255, 1);
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 10vh;
        font-weight: 300;
        margin-top: 10vh;
        text-decoration: underline;
    }

    .slide {
        padding-top: 2vh;
        background-color: black;
        margin-top: -2vh;
        width: 100%;
        text-align: center;
        margin-bottom: 16vh;
    }

    .slide-title {
        color:rgba(255, 255, 255, 1);
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        margin-top: 7vh;
        max-width: 50vw;
        margin-left: 24vw;
    }

    .description {
        color:rgba(255, 255, 255, 1);
        font-size: 1rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        text-align: left;
        margin-left: 24.5vw;
        width: 52%;
        line-height: 1.5rem;
    }
}

@media screen and (max-width: 640px) {
    .code {
        background-color: black;
        color: black;
        height: 1890vh;
        margin-top: 4vh;
        display: flex;
        flex-direction: column;
    }

    .frisky-figures-image {
        max-width: 100vw;
    }

    .frisky-figures {
        margin-bottom: 0vh;
    }

    .frisky-website-image {
        max-width: 100vw;
    }

    .g-mafia-bat-image {
        max-width: 100vw;
    }

    .efficient-frontier-image {
        max-width: 100vw;
    }

    .bug-catcher-login {
        max-width: 100vw;
    }

    .bug-catcher-dash {
        max-width: 100vw;
    }

    .bug-catcher-view {
        max-width: 100vw;
    }

    .pho-banner-image {
        max-width: 100vw;
    }

    .pho-home-image {
        max-width: 100vw;
    }

    .pho-diag-image {
        max-width: 100vw;
    }

    .rec-home-image {
        max-width: 100vw;
    }

    .okcupid-image-1 {
        max-width: 100vw;
    }

    .okcupid-image-2 {
        max-width: 100vw;
    }

    .pierre-image {
        max-width: 100vw;
    }

    .pneum-image-1 {
        max-width: 100vw;
    }

    .pneum-image-2 {
        max-width: 100vw;
    }

    .asl-image-1 {
        max-width: 100vw;
    }

    .asl-image-2 {
        max-width: 100vw;
    }

    .rat-website-image {
        max-width: 100vw;
    }

    .outsite-image {
        max-width: 100vw;
    }

    .decent-labs-image {
        max-width: 100vw;
    }

    .miniExtensions-image {
        max-width: 100vw;
    }

    video {
        display: none;
    }

    .creative-image {
        width: 100vw;
    }

    .creative-image-2 {
        width: 100vw;
    }

    .creative-image-3 {
        width: 100vw;
    }

    .title {
        width: 100%;
    }

    .titleText {
        font-size: 3rem;
        color: rgba(255, 255, 255, 1);
        position: absolute;
        margin-top: 33vh;
        margin-left: 27vw;
        background-color: rgba(80, 200, 120, .5);
        font-weight: 700;
        z-index: 0;
    }

    .intro {
        color:rgba(255, 255, 255, 1);
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 10vh;
        font-weight: 300;
        margin-top: 10vh;
        text-decoration: underline;
    }

    .slide {
        padding-top: 2vh;
        background-color: black;
        margin-top: -2vh;
        width: 100%;
        text-align: center;
        margin-bottom: 6vh;
    }

    .slide-title {
        color:rgba(255, 255, 255, 1);
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        margin-top: 7vh;
        max-width: 80vw;
        margin-left: 10vw;
    }

    .description {
        color:rgba(255, 255, 255, 1);
        font-size: 1rem;
        text-align: center;
        margin-bottom: 5vh;
        font-weight: 300;
        text-align: left;
        margin-left: 10vw;
        width: 80vw;
        line-height: 1.5rem;
    }

}