@media screen and (min-width: 1920px) {
    #h1-writing {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 2rem;
        line-height: 180%;
        width: 70vw;
        text-align: left;
        margin-top: 7%;
        margin-left: 16%;
        letter-spacing: 1.5px;
    }
    
    h1 {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 180%;
        width: 70vw;
        text-align: left;
        margin-top: 7%;
        margin-left: 25%;
        letter-spacing: 1.5px;
    }
    
    .stern {
        margin-top: -3%;
        margin-bottom: 3%;
    }
    
    .stern-image {
        margin-top: 22%;
        width: 20vw;
    }
    
    .side-by-side {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    
    .quanterix-image {
        width: 20vw;
        float: left;
        display: flex;
        margin-right: 50px;
    }
    
    .pph-image {
        width: 30vw;
        float: right;
        display: flex;
        margin-left: 15%;
    }
    
    .phosphorus-image {
        width: 20vw;
        margin-top: 5%;
        margin-bottom: -4%;
        
    }
    
    .dld-image {
        width: 18vw;
        margin-top: 5%;
        margin-bottom: -3%;
    }
    
    .recombine-image {
        width: 20vw;
        margin-top: 5%;
        margin-bottom: -4%;
    }
    
    .mockquestions-image {
        width: 20vw;
        margin-top: 5%;
        margin-bottom: -4%;
    }
    
    .angela-lamb-image {
        width: 25vw;
        margin-top: 4%;
        margin-bottom: -4%;
    }
    
    .nousha-image {
        width: 30vw;
        margin-top: 4%;
        margin-bottom: -4%;
    }
    
    .quanterix-pph {
        margin-top: -5%;
    }
    
    hr {
        margin-top: 5%;
    }
    
    h4 {
        font-weight: 500;
        line-height: 180%;
        width: 50vw;
        margin-left: 25%;
        letter-spacing: 1.5px;
        text-align: left;
        color: rgb(100, 100, 100);
        font-size: 1.5rem;
    }
    
    a {
        text-decoration: none;
        color: rgb(117, 145, 202);
        font-weight: 700;
    }
    
    .nousha-text {
        margin-bottom: 5%;
    }
    
    .additional {
        font-weight: 300;
        line-height: 180%;
        width: 70vw;
        margin-left: 16%;
        letter-spacing: 1.5px;
        text-align: left;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    
    .jf-resume-arrow-2 {
        margin-top: 1%;
        margin-bottom: -3%;
        width: 10vh;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1920px) {
    #h1-writing {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 180%;
        width: 70vw;
        text-align: left;
        margin-top: 7%;
        margin-left: 16%;
        letter-spacing: 1.5px;
    }
    
    h1 {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 180%;
        width: 70vw;
        text-align: left;
        margin-top: 7%;
        margin-left: 16%;
        letter-spacing: 1.5px;
    }
    
    .stern {
        margin-top: -3%;
        margin-bottom: 3%;
    }
    
    .stern-image {
        margin-top: 22%;
        width: 20vw;
    }
    
    .side-by-side {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    
    .quanterix-image {
        width: 20vw;
        float: left;
        display: flex;
        margin-right: 50px;
    }
    
    .pph-image {
        width: 30vw;
        float: right;
        display: flex;
        margin-left: 15%;
    }
    
    .phosphorus-image {
        width: 20vw;
        margin-top: 5%;
        margin-bottom: -4%;
        
    }
    
    .dld-image {
        width: 18vw;
        margin-top: 5%;
        margin-bottom: -3%;
    }
    
    .recombine-image {
        width: 20vw;
        margin-top: 5%;
        margin-bottom: -4%;
    }
    
    .mockquestions-image {
        width: 20vw;
        margin-top: 5%;
        margin-bottom: -4%;
    }
    
    .angela-lamb-image {
        width: 25vw;
        margin-top: 4%;
        margin-bottom: -4%;
    }
    
    .nousha-image {
        width: 30vw;
        margin-top: 4%;
        margin-bottom: -4%;
    }
    
    .quanterix-pph {
        margin-top: -5%;
    }
    
    hr {
        margin-top: 5%;
    }
    
    h4 {
        font-weight: 300;
        line-height: 180%;
        width: 70vw;
        margin-left: 16%;
        letter-spacing: 1.5px;
        text-align: left;
        color: rgb(100, 100, 100);
        font-size: 1.2rem;
    }
    
    a {
        text-decoration: none;
        color: rgb(117, 145, 202);
        font-weight: 700;
    }
    
    .nousha-text {
        margin-bottom: 5%;
    }
    
    .additional {
        font-weight: 300;
        line-height: 180%;
        width: 70vw;
        margin-left: 16%;
        letter-spacing: 1.5px;
        text-align: left;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    
    .jf-resume-arrow-2 {
        margin-top: 1%;
        margin-bottom: -3%;
        width: 10vh;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    #h1-writing {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 180%;
        width: 70vw;
        text-align: left;
        margin-top: 7%;
        margin-left: 16%;
        letter-spacing: 1.5px;
    }
    
    h1 {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 180%;
        width: 70vw;
        text-align: left;
        margin-top: 7%;
        margin-left: 16%;
        letter-spacing: 1.5px;
    }
    
    .stern {
        margin-top: -3%;
        margin-bottom: 3%;
    }
    
    .stern-image {
        margin-top: 22%;
        width: 20vw;
    }
    
    .side-by-side {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
    
    .quanterix-image {
        width: 20vw;
        float: left;
        display: flex;
        margin-right: 50px;
    }
    
    .pph-image {
        width: 30vw;
        float: right;
        display: flex;
        margin-left: 15%;
    }
    
    .phosphorus-image {
        width: 20vw;
        margin-top: 5%;
        margin-bottom: -4%;
        
    }
    
    .dld-image {
        width: 18vw;
        margin-top: 5%;
        margin-bottom: -3%;
    }
    
    .recombine-image {
        width: 20vw;
        margin-top: 5%;
        margin-bottom: -4%;
    }
    
    .mockquestions-image {
        width: 20vw;
        margin-top: 5%;
        margin-bottom: -4%;
    }
    
    .angela-lamb-image {
        width: 25vw;
        margin-top: 4%;
        margin-bottom: -4%;
    }
    
    .nousha-image {
        width: 30vw;
        margin-top: 4%;
        margin-bottom: -4%;
    }
    
    .quanterix-pph {
        margin-top: -5%;
    }
    
    hr {
        margin-top: 5%;
    }
    
    h4 {
        font-weight: 300;
        line-height: 180%;
        width: 70vw;
        margin-left: 16%;
        letter-spacing: 1.5px;
        text-align: left;
        color:rgb(100, 100, 100);
    }
    
    a {
        text-decoration: none;
        color: rgb(117, 145, 202);
        font-weight: 700;
    }
    
    .nousha-text {
        margin-bottom: 5%;
    }
    
    .additional {
        font-weight: 300;
        line-height: 180%;
        width: 70vw;
        margin-left: 16%;
        letter-spacing: 1.5px;
        text-align: left;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    
    .jf-resume-arrow-2 {
        margin-top: 1%;
        margin-bottom: -3%;
        width: 10vh;
    }
}

@media screen and (min-width: 640px) and (max-width: 768px) {
    h1 {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 180%;
        width: 70vw;
        text-align: left;
        margin-top: 7%;
        margin-left: 16%;
        letter-spacing: 1.5px;
    }
    
    .stern {
        margin-top: -3%;
        margin-bottom: 3%;
    }
    
    .stern-image {
        margin-top: 8vh;
        width: 40vw;
    }
    
    .side-by-side {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 5vh;
    }
    
    .quanterix-image {
        width: 30vw;
        float: left;
        display: flex;
    }
    
    .pph-image {
        width: 60vw;
        float: right;
        display: none;
        margin-left: 15%;
    }
    
    .phosphorus-image {
        width: 40vw;
        margin-top: 5%;
        margin-bottom: -4%;
        
    }
    
    .dld-image {
        width: 25vw;
        margin-top: 5%;
        margin-bottom: -3%;
    }
    
    .recombine-image {
        width: 35vw;
        margin-top: 5%;
        margin-bottom: -4%;
    }
    
    .mockquestions-image {
        width: 35vw;
        margin-top: 5%;
        margin-bottom: -4%;
    }
    
    .angela-lamb-image {
        width: 50vw;
        margin-top: 4%;
        margin-bottom: -4%;
    }
    
    .nousha-image {
        width: 50vw;
        margin-top: 4%;
        margin-bottom: -4%;
    }
    
    .quanterix-pph {
        margin-top: -5%;
    }
    
    hr {
        margin-top: 5%;
    }
    
    h4 {
        font-weight: 300;
        line-height: 180%;
        width: 70vw;
        margin-left: 16%;
        letter-spacing: 1.5px;
        text-align: left;
    }
    
    a {
        text-decoration: none;
        color: rgb(117, 145, 202);
        font-weight: 700;
    }
    
    .nousha-text {
        margin-bottom: 5%;
    }
    
    .additional {
        font-weight: 300;
        line-height: 180%;
        width: 70vw;
        margin-left: 16%;
        letter-spacing: 1.5px;
        text-align: left;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    
    .jf-resume-arrow-2 {
        margin-top: 1%;
        margin-bottom: -3%;
        width: 100px;
    }
}

@media screen and (max-width: 640px) {
    .opener {
        margin-top: 5vh;
    }
    
    #h1-writing {
        color: rgb(57, 57, 57);
        font-weight: 400;
        font-size: 1rem;
        line-height: 160%;
        width: 80vw;
        text-align: left;
        margin-left: 10%;
        letter-spacing: 1.5px;
    }
    
    .stern {
        margin-top: -3%;
    }
    
    .stern-image {
        margin-top: 22%;
        width: 70vw;
        margin-bottom: 5vh;
    }
    
    .side-by-side {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-bottom: -8vh;
    }
    
    .quanterix-image {
        width: 70vw;
        float: left;
        display: flex;
        margin-bottom: 7vh;
    }
    
    .pph-image {
        width: 30vw;
        float: right;
        display: none;
        margin-left: 15%;
    }
    
    .phosphorus-image {
        width: 70vw;
        margin-top: 15%;
        margin-bottom: 4vh;
    }
    
    .dld-image {
        width: 60vw;
        margin-top: 10%;
        margin-bottom: 8%;
    }
    
    .recombine-image {
        width: 70vw;
        margin-top: 5vh;
        margin-bottom: 3vh;
    }
    
    .mockquestions-image {
        width: 70vw;
        margin-top: 6vh;
        margin-bottom: 4vh;
    }
    
    .angela-lamb-image {
        width: 80vw;
        margin-top: 5vh;
        margin-bottom: 2vh;
    }
    
    .nousha-image {
        width: 80vw;
        margin-top: 4vh;
        margin-bottom: 2vh;
    }
    
    .quanterix-pph {
        margin-top: -5%;
    }
    
    hr {
        margin-top: 5%;
    }
    
    h4 {
        font-weight: 300;
        line-height: 180%;
        width: 80vw;
        margin-left: 10%;
        letter-spacing: 1.5px;
        text-align: left;
    }
    
    a {
        text-decoration: none;
        color: rgb(117, 145, 202);
        font-weight: 700;
    }
    
    .nousha-text {
        margin-bottom: 5%;
    }
    
    .additional {
        font-weight: 300;
        line-height: 180%;
        width: 70vw;
        margin-left: 16%;
        letter-spacing: 1.5px;
        text-align: left;
        margin-top: 5%;
        margin-bottom: 5vh;
    }
    
    .jf-resume-arrow-2 {
        margin-top: -1vh;
        margin-bottom: -3%;
        width: 100px;
    }
}