@media screen and (min-width: 1920px) {
    .sub-division {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .overall {
        display: block;
    }
    
    .friskybunnies-logo {
        width: 30vw;
        margin-bottom: 20px;
        margin-top: 2%;
        padding-top: 3vh;
    }
    
    #tagline {
        text-align: center;
        letter-spacing: 1.5px;
        font-size: 1.5rem;
        font-weight: 500;
        color: #7592CC;
        margin-bottom: 5%;
    }

    .home {
        height: 89vh;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1920px) {
    .sub-division {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .overall {
        display: block;
    }
    
    .friskybunnies-logo {
        width: 30vw;
        margin-bottom: 20px;
        margin-top: 2%;
        padding-top: 3vh;
    }
    
    #tagline {
        text-align: center;
        letter-spacing: 1.5px;
        font-size: 1.5rem;
        font-weight: 500;
        color: #7592CC;
        margin-bottom: 5%;
    }

    .home {
        height: 84vh;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .sub-division {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .overall {
        display: block;
    }
    
    .friskybunnies-logo {
        max-height: 30vw;
        margin-bottom: 20px;
        margin-top: 3%;
        padding-top: 5vh;
    }
    
    #tagline {
        text-align: center;
        letter-spacing: 1.5px;
        font-size: 1.5rem;
        font-weight: 500;
        color: #7592CC;
        margin-bottom: 5%;
    }

    .home {
        height: 75vh;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .sub-division {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .friskybunnies-logo {
        max-width: 60vw;
        max-height: 40vh;
        margin-bottom: 20px;
        padding-top: 12vh;
    }
    
    #tagline {
        text-align: center;
        letter-spacing: 1.5px;
        font-size: 1.5rem;
        font-weight: 500;
        color: #7592CC;
        margin-bottom: 5%;
    }

    .home {
        height: 82vh;
    }
}

@media screen and (min-width: 640px) and (max-width: 768px) {
    .friskybunnies-logo {
        width: 50vw;
        margin-bottom: 20px;
        margin-top: 8%;
        margin-left: 2%;
    }
    
    #tagline {
        text-align: center;
        letter-spacing: 1.5px;
        font-size: 1.5rem;
        font-weight: 500;
        color: #7592CC;
    }

    .home {
        height: 70vh;
    }
}

@media screen and (max-width: 640px) {
    .friskybunnies-logo {
        width: 80vw;
        margin-bottom: 1vh;
        margin-left: 2%;
        margin-top: 7vh;
    }
    
    #tagline {
        text-align: center;
        letter-spacing: 1.5px;
        font-size: 1.5rem;
        font-weight: 500;
        color: #7592CC;
        margin-bottom: 7%;
        line-height: 2rem;
        margin-left: 5vw;
        margin-right: 5vw;
    }

    .home {
        height: 65vh;
    }
}


