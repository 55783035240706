@media screen and (min-width: 1920px) {
    .performance {
        display: block;
    }
    
    .headshots {
        width: 50vw;
        margin-top: 1%;
    }
    
    .acting-resume {
        width: 59vw;
    }
    
    hr {
        width: 70vw;
    }
    
    li {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 25vw;
        font-size: 1.5rem;
        line-height: 40px;
        margin-right: 15vw;
    }
    
    #header {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 25vw;
        margin-top: 5%;
        font-size: 2rem;
    }
    
    .instagrams {
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 25vw;
        margin-right: 14vw;
        display: flex;
        flex-wrap: wrap;
    }
    
    Iframe {
        margin-right: 3%;
        margin-bottom: 3%;
        width: 18%;
    }
    
    .socials {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 25vw;
        margin-top: 5%;
        font-size: 2rem;
    }
    
    .videos {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 25vw;
        margin-right: 20vw;
        font-size: 1.5rem;
        line-height: 40px;
        margin-bottom: 2%;
    }
    
    .iframe {
        width: 40vw;;
        height: 25vw;
        margin-left: -6%;
        text-align: left;
    }
    
    .iframe-small {
        width: 40vw;;
        height: 25vw;
        margin-left: -13%;
        text-align: left;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1920px) {
    .performance {
        display: block;
    }
    
    .headshots {
        width: 67vw;
        margin-top: 1%;
    }
    
    .acting-resume {
        width: 80vw;
    }
    
    hr {
        width: 70vw;
    }
    
    li {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 17vw;
        font-size: 1.5rem;
        line-height: 40px;
        margin-right: 15vw;
    }
    
    #header {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 17vw;
        margin-top: 5%;
        font-size: 2rem;
    }
    
    .instagrams {
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15vw;
        margin-right: 14vw;
        display: flex;
        flex-wrap: wrap;
    }
    
    Iframe {
        margin-right: 3%;
        margin-bottom: 3%;
        width: 29%;
    }
    
    .socials {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 17vw;
        margin-top: 5%;
        font-size: 2rem;
    }
    
    .videos {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 15vw;
        margin-right: 20vw;
        font-size: 1.5rem;
        line-height: 40px;
        margin-bottom: 2%;
    }
    
    .iframe {
        width: 60vw;;
        height: 40vw;
        margin-left: -6%;
        text-align: left;
    }
    
    .iframe-small {
        width: 60vw;;
        height: 40vw;
        margin-left: -13%;
        text-align: left;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .performance {
        display: block;
    }
    
    .headshots {
        width: 67vw;
        margin-top: 1%;
    }
    
    .acting-resume {
        width: 80vw;
    }
    
    hr {
        width: 70vw;
    }
    
    li {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 17vw;
        font-size: 1.5rem;
        line-height: 40px;
        margin-right: 15vw;
    }
    
    #header {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 17vw;
        margin-top: 5%;
        font-size: 2rem;
    }
    
    .instagrams {
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15vw;
        margin-right: 14vw;
        display: flex;
        flex-wrap: wrap;
    }
    
    Iframe {
        margin-right: 3%;
        margin-bottom: 3%;
        width: 29%;
    }
    
    .socials {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 17vw;
        margin-top: 5%;
        font-size: 2rem;
    }
    
    .videos {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 15vw;
        margin-right: 20vw;
        font-size: 1.5rem;
        line-height: 40px;
        margin-bottom: 2%;
    }
    
    .iframe {
        width: 60vw;;
        height: 40vw;
        margin-left: -6%;
        text-align: left;
    }
    
    .iframe-small {
        width: 60vw;;
        height: 40vw;
        margin-left: -13%;
        text-align: left;
    }
}

@media screen and (min-width: 640px) and (max-width: 768px) {
    .performance {
        display: block;
    }
    
    .headshots {
        width: 80vw;
        margin-top: 5vh;
    }
    
    .acting-resume {
        width: 95vw;
    }
    
    hr {
        width: 70vw;
    }
    
    li {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 10vw;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-right: 10vw;
    }
    
    #header {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 11vw;
        margin-top: 5%;
        font-size: 1.5rem;
    }
    
    .instagrams {
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 11vw;
        margin-right: 0vw;
        display: flex;
        flex-wrap: wrap;
    }
    
    Iframe {
        margin-right: 3%;
        margin-bottom: 3%;
        width: 28%;
        max-height: 30vh;
    }
    
    .socials {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 11vw;
        margin-top: 5%;
        font-size: 1.5rem;
    }
    
    .videos {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 11vw;
        margin-top: 5%;
        font-size: 1.5rem;
        margin-right: 5vw;
        line-height: 2rem;
        margin-bottom: 4vh;
    }
    
    .iframe {
        width: 60vw;;
        height: 40vw;
        margin-left: -12vw;
        text-align: left;
    }
    
    .iframe-small {
        width: 60vw;;
        height: 40vw;
        margin-left: -13%;
        text-align: left;
    }
}

@media screen and (max-width: 640px) {
    .performance {
        display: block;
    }
    
    .headshots {
        width: 80vw;
        margin-top: 5vh;
    }
    
    .acting-resume {
        width: 95vw;
    }
    
    hr {
        width: 70vw;
    }
    
    li {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 10vw;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-right: 10vw;
    }
    
    #header {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 11vw;
        margin-top: 5%;
        font-size: 1.5rem;
    }
    
    .instagrams {
        margin-top: 3vh;
        margin-bottom: 3%;
        margin-left: 11vw;
        margin-right: 0vw;
        display: flex;
        flex-wrap: wrap;
    }
    
    Iframe {
        margin-right: 3%;
        margin-bottom: 3%;
        width: 77vw;
        max-height: 50vh;
    }
    
    .socials {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 11vw;
        margin-top: 5%;
        font-size: 1.5rem;
    }
    
    .videos {
        color: black;
        text-align: left;
        letter-spacing: 1px;
        margin-left: 11vw;
        margin-top: 5%;
        font-size: 1.5rem;
        margin-right: 5vw;
        line-height: 2rem;
        margin-bottom: 4vh;
    }
    
    .iframe {
        width: 80vw;;
        height: 60vw;
        margin-left: 3vw;
        text-align: left;
    }
    
    .iframe-small {
        width: 60vw;;
        height: 40vw;
        margin-left: -13%;
        text-align: left;
    }
}