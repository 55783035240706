@media screen and (min-width: 1920px) {
    html {
        color: rgb(180, 180, 180);
        margin-top: -10px;
    }

    .navbar-nav {
        color: rgb(180, 180, 180);
        margin: 12% 0 0 -30%;
        width: 100%;
        display: flex;
    }
    
    .nav-item {
        list-style-type: none;
        margin-right: 5%;
        color: rgb(180, 180, 180);
        font-size: 12.5px;
        font-weight: 500;
        letter-spacing: 1.5px;
    }
    
    .nav-link {
        text-decoration: none;
        color: rgb(150, 150, 150);
    }
    
    .navbar-brand {
        margin-left: 1%;
        margin-top: 1.5%;
        text-decoration: none;
        color: #7592CC;
        font-size: 32px;
        font-weight: 440;
        white-space: nowrap;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
    }

    .smallnav {
        display: none;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1920px) {
    html {
        color: rgb(180, 180, 180);
        margin-top: -10px;
    }

    .navbar-nav {
        color: rgb(180, 180, 180);
        margin: 12% 0 0 -30%;
        width: 100%;
        display: flex;
    }
    
    .nav-item {
        list-style-type: none;
        margin-right: 5%;
        color: rgb(180, 180, 180);
        font-size: 12.5px;
        font-weight: 500;
        letter-spacing: 1.5px;
    }
    
    .nav-link {
        text-decoration: none;
        color: rgb(150, 150, 150);
    }
    
    .navbar-brand {
        margin-left: 2%;
        margin-top: 2.5%;
        text-decoration: none;
        color: #7592CC;
        font-size: 32px;
        font-weight: 440;
        white-space: nowrap;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
    }

    .smallnav {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    html {
        color: rgb(180, 180, 180);
        margin-top: -10px;
    }

    .navbar-nav {
        color: rgb(180, 180, 180);
        margin: 12% 0 0 -30%;
        width: 100%;
        display: flex;
    }
    
    .nav-item {
        list-style-type: none;
        margin-right: 5%;
        color: rgb(180, 180, 180);
        font-size: 12.5px;
        font-weight: 500;
        letter-spacing: 1.5px;
    }
    
    .nav-link {
        text-decoration: none;
        color: rgb(150, 150, 150);
    }
    
    .navbar-brand {
        margin-left: 2%;
        margin-top: 4%;
        text-decoration: none;
        color: #7592CC;
        font-size: 32px;
        font-weight: 440;
        white-space: nowrap;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
    }

    .smallnav {
        display: none;
    }
}

@media screen and (min-width: 640px) and (max-width: 768px) {
    .bignav {
        display: none;
    }

    .smallnav {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 2vw;
        right: 0vw;
        background: rgba(255, 255, 255, 1);
        width: 100%;
    }

    .navbar-nav {
        color: rgb(180, 180, 180);
        margin: 8% 0 0 -5%;
        width: 100%;
        text-align: right;
    }

    .nav-item {
        list-style-type: none;
        color: rgb(180, 180, 180);
        font-size: 4rem;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 6rem;
    }

    .nav-link {
        text-decoration: none;
        color: rgb(100, 100, 100);
    }

    .navbar-brand {
        margin-left: 2%;
        margin-top: 3%;
        text-decoration: none;
        color: #7592CC;
        font-size: 32px;
        font-weight: 440;
        white-space: nowrap;
        z-index: 5;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
    }

    .menu {
        position: absolute;
        top: 2vw;
        right: 2vw;
    }
}

@media screen and (max-width: 640px) {
    .bignav {
        display: none;
    }

    .smallnav {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 2vw;
        right: 1vw;
        background: rgba(255, 255, 255, 1);
        width: 90%;
        z-index: 1;
    }

    .navbar-nav {
        color: rgb(180, 180, 180);
        margin: 13% 0 0 -5%;
        width: 100%;
        text-align: right;
    }

    .nav-item {
        list-style-type: none;
        color: rgb(180, 180, 180);
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: .5rem;
        line-height: 4rem;
    }

    .nav-link {
        text-decoration: none;
        color: rgb(100, 100, 100);
    }

    .navbar-brand {
        margin-left: 2%;
        margin-top: 3%;
        text-decoration: none;
        color: #7592CC;
        font-size: 32px;
        font-weight: 440;
        white-space: nowrap;
        z-index: 5;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
    }

    .menu {
        position: absolute;
        top: 2vw;
        right: 2vw;
    }
}